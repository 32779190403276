import React from 'react'
import { Alert, AlertTitle } from '@mui/material'

interface ErrorProps {
	header?: string,
	action?: React.ReactNode,
	center?: boolean,
	error?: string
}

const ErrorAlertInner = (props: ErrorProps) => {
	return (
		<Alert severity="error" action={props.action}>
			{props.header ? <AlertTitle>{props.header}</AlertTitle> : null}
			{props.error ? props.error : 'Fehler beim laden der Daten'}
		</Alert>
	)
}

export const ErrorAlert = (props: ErrorProps) => {
	if (props.center) {
		return (
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
				<ErrorAlertInner {...props} />
			</div>
		)
	}

	return (
		<ErrorAlertInner {...props} />
	)
}