import { ServersideModel } from 'general/XtServersideDataGrid'
import xhttp from 'query/xhttp'

export type AdminListResponse<Type> = {
	status: true,
	data: Type[],
	total: number
}

export type AdminDetailResponse<Type> = {
	status: true,
	data: Type
}

export const filterToFormdata = (filter?: ServersideModel): FormData => {
	const params = new FormData()

	if (!filter) {
		return params
	}

	if (filter.pageSize) {
		params.append('pageSize', filter.pageSize.toString())
	}

	if (filter.search) {
		params.append('search', filter.search)
	}

	if (filter.sort?.field) {
		params.append('sort[field]', filter.sort.field)
		if (filter.sort.sort) {
			params.append('sort[direction]', filter.sort.sort)
		}
	}

	if (filter.page) {
		params.append('page', filter.page.toString())
	}

	if (filter.filter) {
		filter.filter.items.forEach((filter, index) => {
			params.append(`filter[${index}][field]`, filter.field)
			params.append(`filter[${index}][operator]`, filter.operator || '')
			
			if (filter.value instanceof Date) {
				params.append(`filter[${index}][value]`, filter.value.toISOString())
			} else {
				params.append(`filter[${index}][value]`, filter.value)
			}
		})
	}

	return params
}

const formatValue = (value: string | number | null | undefined): string => {
	if (value === null || value === undefined) {
		return ''
	}

	return value.toString()
}

export const objectToFormdata = (data: {[key: string]: string | number | null | undefined}) => {
	const params = new FormData()

	Object.keys(data).forEach(key => {
		params.append(key, formatValue(data[key]))
	})

	return params
}

const formDataToQuery = (formData: FormData): string => {
	const params = new URLSearchParams()

	formData.forEach((value, key) => {
		params.append(key, value as string)
	})

	return params.toString()
}

export const fetchAdmin = async (url: string, params?: FormData, method = 'GET', queryParams?: FormData) => {
	if (method === 'POST') {
		return xhttp.post('provider/' + url + '.php' + (queryParams && Array.from(queryParams.entries()).length > 0 ? '?' + formDataToQuery(queryParams) : ''), params).then(res => res.data)
	}

	const fullUrl = 'provider/' + url + '.php' + (params && Array.from(params.entries()).length > 0 ? '?' + formDataToQuery(params) : '')

	return await xhttp.get(fullUrl).then(res => res.data)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postAdmin = async (url: string, params?: any) => {
	return xhttp.post('provider/' + url + '.php', params, {
		headers: {
			'Content-Type': 'application/json'
		}
	}).then(res => res.data)
}

export const downloadAdminUrl = (url: string, filterModel: ServersideModel): string => {
	const params = filterToFormdata(filterModel)
	params.append('download', '1')
	
	return '/provider/' + url + '.php' + (params && Array.from(params.entries()).length > 0 ? '?' + formDataToQuery(params) : '')
}