import React, { ChangeEvent, createContext, SyntheticEvent, useEffect, useState } from 'react';

import { TreeItemContentProps, TreeItemProps, SimpleTreeView, useTreeItemState, TreeItem2 } from '@mui/x-tree-view';
import { Alert, AppBar, Avatar, Badge, Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid2, IconButton, ImageList, ImageListItem, ImageListItemBar, List, ListItem, ListItemAvatar, ListItemText, Radio, Slide, TextField, Toolbar, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useSnackbar } from 'notistack';
import jsonp from 'jsonp';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FolderTwoToneIcon from '@mui/icons-material/FolderTwoTone';
import PhotoTwoToneIcon from '@mui/icons-material/PhotoTwoTone';
import SaveIcon from '@mui/icons-material/Save';
import UploadFileTwoToneIcon from '@mui/icons-material/UploadFileTwoTone';

import { ErrorAlert } from 'general/global/ErrorAlert';
import { Loading } from 'general/global/Loading';
import { ThreeItems, loadFilesQuery, threeItemsQuery } from 'query/monitor/filepicker';
import { useDropzone } from 'react-dropzone';
import xhttp from 'query/xhttp';

const MEDIA_BASE = '//media.xmlteam.de'


const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

type FileManagerContext = {
	openFilepicker: (params?: openFilepickerProps) => void
}

export const FileManagerContext = createContext<FileManagerContext>(
	{} as FileManagerContext
)

const CustomContent = React.forwardRef(function CustomContent(
	props: TreeItemContentProps,
	ref,
) {
	const {
		classes,
		className,
		label,
		itemId,
		icon: iconProp,
		expansionIcon,
		displayIcon,
	} = props;

	const {
		expanded,
		selected,
		focused,
		handleExpansion,
		handleSelection,
		preventSelection,
	} = useTreeItemState(itemId);

	const icon = iconProp || expansionIcon || displayIcon;

	const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		preventSelection(event);
	};

	const handleExpansionClick = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
	) => {
		handleExpansion(event);
	};

	const handleSelectionClick = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
	) => {
		handleSelection(event);
	};

	return (
		<div className={[className, classes.root, (expanded ? classes.expanded : ''), (selected ? classes.selected : ''), (focused ? classes.focused : '')].join(' ')} onMouseDown={handleMouseDown} ref={ref as React.Ref<HTMLDivElement>}>
			<div onClick={handleExpansionClick} className={classes.iconContainer}>
				{icon}
			</div>
			<Typography onClick={handleSelectionClick} component="div" className={classes.label}>
				{label}
			</Typography>
		</div>
	);
});

function CustomTreeItem(props: TreeItemProps) {
	return <TreeItem2 ContentComponent={CustomContent} {...props} />;
}

interface FilesListProps {
	files: string[] | undefined
	path: string
	isLoading: boolean
	isError: boolean
	values: string[]
	multiple: boolean
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const FilesList = ({ files, isLoading, isError, path, values, multiple, onChange }: FilesListProps) => {
	if (path === '/files' || files === undefined) {
		return null
	}

	if (isLoading) {
		return <Loading />
	}

	if (isError) {
		return <ErrorAlert center={true} />
	}

	if (files.length === 0) {
		return (
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
				<Alert severity="info">
					In diesem Ordner befinden sich keine Dateien
				</Alert>
			</div>
		)
	}

	return (
		<ImageList sx={{ maxHeight: '100%', overflowY: 'auto', py: 0, my: 0 }} cols={4} rowHeight={300}>
			{files.map((img: string, index: number) => (
				<ImageListItem key={index} sx={{ overflow: 'hidden' }}>
					<img src={MEDIA_BASE + img.replace('/files/', '/t/m/s/')} loading="lazy" />
					<ImageListItemBar title={multiple ?
						<FormControlLabel value={img} sx={{ width: '100%' }} control={<Checkbox name="file[]" onChange={onChange} checked={values.find(path => path.indexOf(img) !== -1) !== undefined} />} label={img.split('/').pop()} /> :
						<FormControlLabel value={img} sx={{ width: '100%' }} control={<Radio name="file" onChange={onChange} checked={values.find(path => path.indexOf(img) !== -1) !== undefined} />} label={img.split('/').pop()} />
					} actionIcon={
						<IconButton onClick={() => window.open(MEDIA_BASE + img)}>
							<PhotoTwoToneIcon />
						</IconButton>
					} />
				</ImageListItem>
			))}
		</ImageList>
	)
}

const units = ['B', 'KB', 'MB', 'GB'];

const formatBytes = (bytes: number) => {
	let l = 0, n = bytes || 0;
  
	while(n >= 1024 && ++l){
		n = n/1024;
	}
	
	return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
}

interface openFilepickerProps {
	path?: string
	multiple?: boolean
	values?: string[]
	onClose?: (values: string[]) => void
}

interface FileManagerState {
	open: boolean
	multiple: boolean
	path: string
	selectedFiles: string[]
	onClose?: (values: string[]) => void
	createDirOpen: boolean
}

export const FileManager = ({ children }: React.PropsWithChildren) => {
	const [state, setState] = useState<FileManagerState>({
		open: false,
		multiple: true,
		path: '/files',
		selectedFiles: [],
		createDirOpen: false
	})

	const [expanded, setExpanded] = useState<string[]>([])

	useEffect(() => {
		const ex: Array<string> = []

		state.path.split('/').forEach((path, index) => {
			if (index > 0) {
				ex.push(state.path.split('/').slice(0, index + 1).join('/'))
			}
		})

		setExpanded(ex)
	}, [state.path])

	const filesQuery = loadFilesQuery(state.path)
	const { isLoading, isError, data, refetch } = threeItemsQuery(state.open)

	const { enqueueSnackbar } = useSnackbar()

	const openFilepicker = (params?: openFilepickerProps) => {
		if (params?.values && params.values.length > 0) {
			const path = params?.values[0].replace(MEDIA_BASE, '').split('/')
			path.pop()
			params.path = path.join('/')
		} else {
			if (params?.path && state.path.indexOf(params.path) !== -1) {
				params.path = state.path
			}
		}

		const newState: FileManagerState = {
			open: true,
			multiple: params?.multiple || false,
			path: params?.path || '/files',
			selectedFiles: params?.values || [],
			createDirOpen: false
		}

		if (params?.onClose) {
			newState.onClose = params.onClose
		}

		setState(newState)
	}

	const handleClose = () => {
		if (state.onClose) {
			state.onClose(state.selectedFiles)
		}
		setState({
			...state,
			selectedFiles: [],
			open: false
		})
	}

	// three
	const renderTree = (nodes: ThreeItems) => (
		<CustomTreeItem key={nodes.path} itemId={nodes.path} label={nodes.name}>
			{nodes.children !== undefined ? nodes.children.map((node: ThreeItems) => renderTree(node)) : null}
		</CustomTreeItem>
	);

	const handleNodeSelect = (event: SyntheticEvent, itemIds: string | null) => {
		setState({
			...state,
			path: itemIds || '/files'
		})
	}

	// images
	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (state.multiple) {
			if (event.target.checked) {
				setState({
					...state,
					selectedFiles: [...state.selectedFiles, '//media.xmlteam.de' + event.target.value]
				})
			} else {
				setState({
					...state,
					selectedFiles: state.selectedFiles.filter(path => path.indexOf(event.target.value) === -1)
				})
			}
		} else {
			setState({
				...state,
				selectedFiles: ['//media.xmlteam.de' + event.target.value]
			})
		}
	}

	const clearSelection = () => {
		setState({
			...state,
			selectedFiles: []
		})
	}

	// create dir
	const createDir = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		const formData = new FormData(e.target as HTMLFormElement)
		formData.append('path', state.path)

		if (formData.get('name') === '') {
			enqueueSnackbar('Bitte einen Namen eingeben', {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center'
				},
				autoHideDuration: 1500
			})
			return
		}

		const data = [...formData.entries()];
		const asString = data
			.map(x => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1] as string)}`)
			.join('&');

		jsonp('https://media.xmlteam.de/api/mkdir.php?' + asString, (error, data: { status: boolean, message: string }) => {
			if (data.status) {
				(e.target as HTMLFormElement).reset()
				enqueueSnackbar('Ordner erstellt', { variant: 'success' })
				refetch()
			} else {
				enqueueSnackbar(error?.message || data.message, { variant: 'error' })
			}
		})
	}

	// upload
	const [ updloadOpen, setUpdloadOpen ] = useState(false)

	const {getRootProps, getInputProps, acceptedFiles} = useDropzone({
		onDrop: (acceptedFiles) => {
			const formData = new FormData()
			formData.append('media[path]', state.path)
			acceptedFiles.forEach((file: File, index: number) => {
				formData.append('media[file][' + index + ']', file, file.name)
			})

			xhttp.post('https://media.xmlteam.de/api/upload.php?alid=' + localStorage.getItem('alid'), formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}).then(data => {
				if (data.data.status) {
					enqueueSnackbar('Dateien hochgeladen', { variant: 'success' })
					setUpdloadOpen(false)
					refetch()
				} else {
					enqueueSnackbar(data.data.message, { variant: 'error' })
				}
			}).catch(err => {
				enqueueSnackbar(err.message, { variant: 'error' })
			})
		}
	})


	return (
		<FileManagerContext.Provider value={{ openFilepicker }}>
			{children}
			<Dialog fullScreen open={state.open} onClose={handleClose} TransitionComponent={Transition}>
				<AppBar sx={{ position: 'relative' }} color="transparent">
					<Toolbar variant="dense">
						<Typography sx={{ flex: 1 }} variant="h6" component="div">
							Dateiverwaltung
						</Typography>

						<Toolbar component="form" onSubmit={createDir}>
							<TextField name="name" variant="standard" label="Ordnername" sx={{ mr: 2 }} />
							<Button sx={{ mr: 1 }} variant="contained" type="submit">Ordner erstellen</Button>
						</Toolbar>

						<Button sx={{ mr: 1 }} startIcon={<UploadFileTwoToneIcon />} onClick={() => setUpdloadOpen(true)}>Dateien hochladen</Button>
						<Button sx={{ mr: 1 }} disabled={state.selectedFiles.length === 0} onClick={clearSelection}>
							Auswahl aufheben <Badge badgeContent={state.selectedFiles.length} color="primary" />
						</Button>
						<Button startIcon={<SaveIcon />} onClick={handleClose}>
							übernehmen und schließen
						</Button>
					</Toolbar>
				</AppBar>
				<Grid2 container spacing={0} sx={{ maxHeight: 'calc(100% - 48px)', overflow: 'hidden' }}>
					<Grid2 size={2} sx={{ maxHeight: '100%', overflowY: 'auto' }}>
						{isLoading && <Loading />}
						{isError && <ErrorAlert />}
						{data && (
							<SimpleTreeView expandedItems={expanded} slots={{ 
								collapseIcon: ExpandMoreIcon,
								endIcon: FolderTwoToneIcon,
								expandIcon: ChevronRightIcon
							}} onSelectedItemsChange={handleNodeSelect}>
								{renderTree(data.data)}
							</SimpleTreeView>)
						}
					</Grid2>
					<Grid2 size={10} sx={{ height: '100%' }}>
						<FilesList files={filesQuery.data?.data} path={state.path} values={state.selectedFiles} multiple={state.multiple} isLoading={filesQuery.isLoading} isError={filesQuery.isError} onChange={handleInputChange} />
					</Grid2>
				</Grid2>
			</Dialog>
			<Dialog open={updloadOpen} onClose={() => setUpdloadOpen(false)} maxWidth="sm" fullWidth={true}>
				<DialogTitle>Upload</DialogTitle>
				<DialogContent>
					<Box sx={{ p: 1, textAlign: 'center' }}>
						<Button {...getRootProps()} variant="contained">
							<input {...getInputProps()} />
							Dateien wählen
						</Button>
					</Box>
					<List dense={true}>
						{acceptedFiles.map((file: File) => (
							<ListItem key={file.name}>
								<ListItemAvatar>
									<Avatar src={URL.createObjectURL(file)} />
								</ListItemAvatar>
								<ListItemText primary={file.name} secondary={formatBytes(file.size)} />
							</ListItem>
						))}
					</List>
					{acceptedFiles.length > 0 && (
						<Box sx={{ p: 1, textAlign: 'center' }}>
							{acceptedFiles.length} Dateien werden in {state.path} hochladen
						</Box>
					)}
				</DialogContent>
			</Dialog>
		</FileManagerContext.Provider>
	)
}
