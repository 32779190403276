import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React, { createContext, useEffect, useState } from 'react'

type XtConfirmContext = {
	setConfirm: (params: XtConfirmState) => void,
}

export const XtConfirmContext = createContext<XtConfirmContext>(
	{} as XtConfirmContext
)

interface XtConfirmState {
	title: string,
	content?: string | JSX.Element,
	onConfirm: () => void,
}

export const XtConfirm = ({ children }: React.PropsWithChildren) => {

	const [open, setOpen] = useState(false)
	const handleCloseModal = () => setOpen(false)

	const [ state, setConfirm ] = useState<XtConfirmState>({
		title: undefined as unknown as string,
		content: undefined as unknown as string | JSX.Element,
		onConfirm: undefined as unknown as () => void,
	})

	useEffect(() => {
		if (state.title) {
			setOpen(true)
		}
	}, [state])

	return (
		<XtConfirmContext.Provider value={{ setConfirm }}>
			{open && <Dialog open={open} onClose={handleCloseModal} maxWidth="xs" fullWidth={true}>
				<DialogTitle>{state.title}</DialogTitle>
				{state.content && <DialogContent>{state.content}</DialogContent>}
				<DialogActions>
					<Button onClick={async () => {
						state.onConfirm()
						setOpen(false)
					}}>Ja</Button>
					<Button onClick={handleCloseModal} color="error">Nein</Button>
				</DialogActions>
			</Dialog>}
			{children}
		</XtConfirmContext.Provider>
	)
}