import axios from 'axios'

export type AdminResponseOk<Type> = {
	status: true,
	data: Type[] | Type,
	total?: number
}

export type AdminResponseError = {
	status: false,
	message: string
}

export type AdminResponseTraceError = AdminResponseError & {
	trace: Array<errorTrace>,
	file: string,
	line: number
}

export type errorTrace = {
	file: string,
	line: number,
	function: string,
	class: string,
	type: string,
}

export type AdminResponse<Type = unknown> = AdminResponseOk<Type> | AdminResponseError

export const isAdminResponseOk = (response: AdminResponse<unknown>): response is AdminResponseOk<unknown> => response.status

export const showAdminError = (response: AdminResponseError | AdminResponseTraceError): response is AdminResponseTraceError => 'trace' in response

export class AdminError extends Error {
	private response: AdminResponseError | AdminResponseTraceError

	constructor(message: string, response: AdminResponseError | AdminResponseTraceError) {
		super(message)
		this.response = response
	}

	getResponse() {
		return this.response
	}
}

const transformResponse = (data: string) => {
	try {
		const json: AdminResponse = JSON.parse(data)

		if (!isAdminResponseOk(json)) {
			throw new AdminError(json.message, json)
		}
		
		return json
	} catch (error) {
		if (error instanceof AdminError) {
			throw error
		}

		throw new Error('Invalid JSON response')
	}
}

const xhttp = axios.create({ transformResponse })

export const validateUrl = (url?: string | null, origin?: string) => {
	if (!url) {
		return ''
	}

	const parsed = new URL(url, origin)
	return 'https:' === parsed.protocol ? url : ''
}

export default xhttp