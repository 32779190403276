import React from 'react'
import { useMediaQuery, ThemeProvider, CssBaseline } from '@mui/material';
import { ThemeOptions, createTheme, Theme } from '@mui/material/styles';
import { createContext, useState } from 'react';

const basics: ThemeOptions = {
	typography: {
		fontFamily: 'Roboto',
		fontSize: 12
	},
	shape: {
		borderRadius: 5,
	}
}

const lightThemeOptions: ThemeOptions = {
	palette: {
		mode: 'light',
		primary: {
			main: '#1976d2',
		},
		secondary: {
			main: '#d27619',
		},
	},
	...basics
};

const darkThemeOptions: ThemeOptions = {
	palette: {
		mode: 'dark',
		primary: {
			main: '#4cceac'
		},
		secondary: {
			main: '#ce4c6f'
		},
		info: {
			main: '#0288d1'
		}
	},
	...basics
}

export const getThemePalette = (dark: boolean): Theme => {
	return createTheme(dark ? darkThemeOptions : lightThemeOptions)
}

type DarkmodeOn = boolean
type DarkmodeContext = {
	dark: DarkmodeOn,
	setDark: (newValue: boolean) => void
}

export const DarkmodeContext = createContext<DarkmodeContext>(
	{} as DarkmodeContext
)

export const Darkmode = ({ children }: React.PropsWithChildren) => {

	const mediaQuery = useMediaQuery('(prefers-color-scheme: dark)')
	const localValue = window.localStorage.getItem('dark_mode')

	const [theme, setTheme] = useState<DarkmodeOn>(localValue !== null ? JSON.parse(localValue) : mediaQuery)

	const setDark = (newValue: boolean) => {
		window.localStorage.setItem('dark_mode', JSON.stringify(newValue))
		setTheme(newValue)
	}

	return (
		<DarkmodeContext.Provider value={{ dark: theme, setDark }}>
			<ThemeProvider theme={getThemePalette(theme)}>
				<CssBaseline />
				{children}
			</ThemeProvider>
		</DarkmodeContext.Provider>
	)
}
