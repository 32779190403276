import React, { createContext, FunctionComponent, useState } from 'react'
import loadComponent from 'query/componentLoader';
import { IFrame } from 'general/IFrame';
import { getComponent } from 'query/componentGetter';
import { validateUrl } from 'query/xhttp';

export interface Tab {
	id: string | number,
	label: string,
	component?: string,
	loadedComponent?: FunctionComponent,
	params?: { [key: string]: string|number }
}

interface TabStore {
	tabs: Tab[],
	activeTab: number | undefined,
	openTab: (tab: Tab) => void,
	closeTab: (tab: Tab) => void,
	activateTab: (index: number) => void,
	updateTab: (tab: Tab, index?: number) => void,
}

export const TabContext = createContext({} as TabStore)

const getInitialTab = (): Tab[] => {
	const tabs: Tab[] = []

	const hash = window.location.hash.substring(1)

	if (hash) {
		const [ component, ...params ] = hash.split(':')

		const tab: Tab | undefined = getComponent(component, params)

		if (tab) {
			tabs.push(tab)
		}
	}

	return tabs
}

export default function TabProvider ({ children }: React.PropsWithChildren) {
	const [ tabs, setTabs ] = useState<Tab[]>(getInitialTab())
	const [ activeTab, setActiveTab ] = useState<number | undefined>(tabs.length > 0 ? 0 : undefined)

	const store = {
		tabs,
		activeTab,
		openTab: async (tab: Tab) => {
			const find = tabs.findIndex((t: Tab) => t.id === tab.id)

			if (find === -1) {
				if (tab.component === 'iframe') {
					// Open URL in iframe
					tab.loadedComponent = () => <IFrame src={validateUrl(tab.params?.src as string, window.location.origin)} />
				} else {
					// not found = open it
					// load component
					if (!tab.loadedComponent && tab.component) {
						tab.loadedComponent = loadComponent(tab.component)
					}
				}
				
				setTabs([...tabs, tab])
				setActiveTab(tabs.length)
			} else {
				setActiveTab(find)
			}
		},
		closeTab: (tab: Tab) => {
			const newTabs = tabs.filter((t: Tab) => t.id !== tab.id)

			if (activeTab !== undefined) {
				const newActive = newTabs.findIndex((t: Tab) => t.id === tabs[activeTab].id)
				
				if (newActive !== -1) {
					store.activateTab(newActive)
				} else {
					if (newTabs.length === 0) {
						store.activateTab(undefined)
					}

					if (newTabs[activeTab] === undefined) {
						store.activateTab(activeTab - 1)
					}
				}
			}

			setTabs(newTabs)
		},
		activateTab: (index: number | undefined) => {
			if (activeTab !== index) {
				setActiveTab(index)
			}
		},
		updateTab: (tab: Partial<Tab>, index?: number) => {
			const newTabs = [ ...tabs ]

			if (index !== undefined) {
				newTabs[index] = { ...newTabs[index], ...tab }
			} else {
				if (activeTab === undefined) {
					return
				}
				newTabs[activeTab] = { ...newTabs[activeTab], ...tab }
			}

			setTabs(newTabs)
			setActiveTab(index || activeTab)
		}
	}

	return (
		<TabContext.Provider value={{ ...store }}>
			{children}
		</TabContext.Provider>
	)
}