import React, { useState } from 'react'
import { CircularProgress } from '@mui/material'
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/de';

import rememberUserQuery, { ILogin, startRefetch } from 'query/rememberUser'
import AdminArea from 'general/AdminArea'
import LoginForm from 'general/LoginForm'
import { useEffect } from 'react'
import TabProvider from 'general/context/TabContext'
import { XtConfirm } from 'general/context/XtConfirm'
import { FileManager } from 'general/context/FileManager'
import { UserProvider } from 'general/context/UserContext';

const isLogin = (login: ILogin | undefined): login is ILogin => login !== undefined

const Login = () => {
	const [ login, setLogin ] = useState<ILogin | undefined>(undefined)

	const { data, refetch, isFetching } = rememberUserQuery()

	useEffect(() => {
		if (data && isLogin(data.data)) {
			setLogin(data.data)
		}
	}, [ data ])

	useEffect(() => {
		if (login) {
			const interval = startRefetch({ refetch })
			return () => clearInterval(interval)
		}
	}, [ login ])

	if (login) {
		return (
			<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
				<SnackbarProvider>
					<UserProvider>
						<FileManager>
							<XtConfirm>
								<TabProvider>
									<AdminArea login={login} key="admin-area" />
								</TabProvider>
							</XtConfirm>
						</FileManager>
					</UserProvider>
				</SnackbarProvider>
			</LocalizationProvider>
		)
	}

	if (isFetching || data) {
		return (
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
				<CircularProgress color="secondary" />
			</div>
		)
	}

	return (
		<LoginForm setLogin={setLogin} />
	)
}

export default Login