import React, { Suspense, useContext, useEffect, useState  } from 'react'

import { Avatar, Box, Divider, Drawer, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tab, Tabs, Tooltip, Typography } from '@mui/material'
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import { styled, useTheme } from '@mui/material/styles';
import dayjs from 'dayjs'
import * as yup from 'yup'

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import LightModeTwoToneIcon from '@mui/icons-material/LightModeTwoTone';
import DarkModeTwoToneIcon from '@mui/icons-material/DarkModeTwoTone';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { ILogin, INavigation } from 'query/rememberUser'
import { DarkmodeContext } from 'general/context/Darkmode';
import { TabContext, Tab as ITab } from 'general/context/TabContext';
import { TabContent } from 'general/TabContent';
import { Loading } from 'general/global/Loading';
import { UserContext } from 'general/context/UserContext';
import { validateUrl } from 'query/xhttp';
import loadComponent from 'query/componentLoader';

// add global rule for dayjs
yup.addMethod(yup.object, 'dayjs', function method(messge) {
	return this.test('dayjs', messge, function (value) {
		if (!value) {
			return true
		}
		return dayjs.isDayjs(value)
	})
})

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
	'& .MuiTreeItem-content': {
		borderRadius: 0,
		'&:hover': {
			backgroundColor: theme.palette.action.hover
		},
		paddingTop: 1.5,
		paddingBottom: 1.5,
	},
	'& > .MuiTreeItem-content .MuiTreeItem-label': {
		fontWeight: theme.typography.fontWeightBold,
	},
	'& .MuiTreeItem-label': {
		padding: theme.spacing(0.1, 0)
	},
	'& .Mui-selected': {
		borderLeftWidth: 1,
		borderLeftStyle: 'solid',
		borderLeftColor: theme.palette.primary.main,
		marginLeft: '-1px'
	}
}));

const StyledTreeItemRootNoChildren = styled(TreeItem)(({ theme }) => ({
	'& .MuiTreeItem-content': {
		borderRadius: 0,
		'&:hover': {
			backgroundColor: theme.palette.action.hover
		},
		paddingTop: 1.5,
		paddingBottom: 1.5,
	},
	'& .MuiTreeItem-label': {
		padding: theme.spacing(0.1, 0)
	},
	'& .Mui-selected': {
		borderLeftWidth: 1,
		borderLeftStyle: 'solid',
		borderLeftColor: theme.palette.primary.main,
		marginLeft: '-1px'
	}
}));

interface AdminAreaProps {
	login: ILogin
}

const renderTree = (item: INavigation) => {
	if (item.children === undefined) {
		return (
			<StyledTreeItemRootNoChildren key={item.id} itemId={'menu_' + item.id} label={item.label} />
		)
	}

	return (
		<StyledTreeItemRoot key={item.id} itemId={'menu_' + item.id} label={item.label}>
			{item.children.map(renderChildTree)}
		</StyledTreeItemRoot>
	)
}

const renderChildTree = (item: INavigation) => {
	return (
		<TreeItem key={item.id} itemId={'menu_' + item.id} label={item.label} sx={{ borderLeft: 1, borderLeftColor: 'divider', borderLeftStyle: 'solid' }}>
			{item.children ? item.children.map(renderChildTree) : null}
		</TreeItem>
	)
}

const findNavigationById = (tabs: INavigation[], id: string): ITab => {
	let flat: Array<INavigation> = []
	let found: INavigation | undefined = undefined

	tabs.forEach((tab) => {
		if ('menu_' + tab.id == id) {
			found = tab
		}

		if (tab.children !== undefined) {
			flat = flat.concat(tab.children)
		}
	})

	if (found) {
		return found
	}

	return findNavigationById(flat, id)
}

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
	justifyContent: 'flex-end',
}));

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
	open?: boolean;
}>(({ theme, open }) => ({
	flexGrow: 1,
	padding: 0,
	transition: theme.transitions.create('margin', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	maxWidth: '100%',
	overflow: 'hidden',
	marginLeft: `-${drawerWidth}px`,
	...(open && {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
	}),
}));

const allowSidebarOpen = () =>{	
	const params = new URLSearchParams(window.location.search)
	if (params.has('sidebar')) {
		return params.get('sidebar') === 'false'  ? false : true
	}

	// fallback/bc
	return ['#vorgang', '#vorgangsverwaltung'].includes(window.location.hash.split(':')[0]) ? false : true
	// später true oder window.innerWidth <=> [Breakpoint?] + useEffect onWindowResize
	return true;
}

const AdminArea = ({ login }: AdminAreaProps) => {
	const { dark, setDark } = useContext(DarkmodeContext)
	const { setUser, isAdmin, user } = useContext(UserContext)
	const { tabs, openTab, activeTab, activateTab, closeTab } = useContext(TabContext)
	const [ openSidebar, setOpenSidebar ] = useState(allowSidebarOpen())

	const theme = useTheme()

	const toggleDarkmode = () => {
		setDark(!dark)
	}

	useEffect(() => {
		setUser(login)
		localStorage.setItem('alid', login.alid);
	}, [login])

	useEffect(() => {
		if (isAdmin() && tabs.length === 0) {
			const Loaded = loadComponent('AdminDashboard')
			openTab({
				id: 'dashboard',
				label: 'Dashboard',
				loadedComponent: () => <Loaded />
			})
		}
	}, [user])

	const handleLogout = () => {
		localStorage.removeItem('alid');
		window.location.href = 'logout.php'
	}

	// tab callbacks
	const handleTabChange = (e: React.SyntheticEvent, newValue: number) => {
		activateTab(newValue)
	}

	const handleTabClose = (event: React.SyntheticEvent, tab: ITab) => {
		event.stopPropagation()
		closeTab(tab)
		return false
	}

	// sidebar handler
	const handleSidebarOpen = () => {
		setOpenSidebar(true)
	}

	const handleSidebarClose = () => {
		setOpenSidebar(false)
	}

	// tree callbacks
	const handleNodeSelect = (e: React.SyntheticEvent, value: string | null) => {
		if (!value) {
			return
		}

		const tab: ITab = findNavigationById(login.navigation, value)

		if (tab.component === 'window') {
			window.open(validateUrl(tab.params?.src as string))?.focus();
		} else if (tab.component) {
			openTab(tab)
		}
	}

	// user menu
	const [ anchorElUser, setAnchorElUser ] = useState<null | HTMLElement>(null);

	const openUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget)
	}

	const closeUserMenu = () => {
		setAnchorElUser(null)
	}

	return (
		<Box sx={{ display: 'flex' }}>
			<Drawer sx={{ width: drawerWidth, flexShrink: 0, '& .MuiDrawer-paper': { width: drawerWidth } }} open={openSidebar} variant="persistent" anchor="left">
				<DrawerHeader>
					<Typography sx={{ ml: 1, mr: 'auto' }}>{login.name}</Typography>
					<IconButton onClick={handleSidebarClose}>
						<ChevronLeftIcon />
					</IconButton>
				</DrawerHeader>
				<Divider />
				<SimpleTreeView slots={{ collapseIcon: ExpandMoreIcon, expandIcon: ChevronRightIcon, endIcon: ArrowRightIcon }} onSelectedItemsChange={handleNodeSelect}>
					{login.navigation.map(renderTree)}
				</SimpleTreeView>
			</Drawer>

			<Main open={openSidebar}>
				<Box sx={{ display: 'flex', borderBottom: 1, borderColor: 'divider' }}>
					<IconButton color="inherit" onClick={handleSidebarOpen} sx={{ mr: 2, ...(openSidebar && { display: 'none' }) }}>
						<MenuIcon />
					</IconButton>

					<Tabs value={activeTab} onChange={handleTabChange} variant="scrollable" sx={{ flexGrow: 1 }}>
						{tabs.map((tab) => {
							return (
								<Tab key={tab.id} label={(
									<span>
										{tab.label} <IconButton component="div" onClick={(e) => handleTabClose(e, tab)}><CloseIcon /></IconButton>
									</span>
								)} sx={{ px: 1, py: 0.5 }} />
							)
						})}
					</Tabs>

					<Tooltip title="Einstellungen">
						<IconButton onClick={openUserMenu} sx={{ p: 0, ml: 'auto', pr: 1 }}>
							<Avatar sx={{ bgcolor: theme.palette.secondary.main }}>{login.letters}</Avatar>
						</IconButton>
					</Tooltip>
					<Menu id="menu-appbar" keepMounted open={Boolean(anchorElUser)} anchorEl={anchorElUser} onClose={closeUserMenu}>
						<MenuItem onClick={toggleDarkmode}>
							<ListItemIcon>
								{dark ? <DarkModeTwoToneIcon /> : <LightModeTwoToneIcon />}
							</ListItemIcon>
							<ListItemText>Darkmode</ListItemText>
						</MenuItem>
						<Divider />
						<MenuItem onClick={handleLogout}>
							<ListItemIcon>
								<LogoutTwoToneIcon />
							</ListItemIcon>
							<ListItemText>Ausloggen</ListItemText>
						</MenuItem>
					</Menu>
				</Box>
				<Suspense fallback={<Loading />}>
					{tabs.map((tab: ITab, index: number) => {
						const Component = tab.loadedComponent as React.FC
						return (
							<TabContent key={tab.id} id={tab.id.toString()} active={activeTab === index}>
								<Component />
							</TabContent>
						)
					})}
				</Suspense>
			</Main>
		</Box>
	)
}

export default AdminArea