import React, { useState } from 'react'
import { Container, Box, Avatar, Typography, TextField, Alert, Button } from '@mui/material'  // FormControlLabel, Checkbox,
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { fetchAdmin } from 'query/fetchAdmin'
import { ILogin } from 'query/rememberUser'

interface LoginFormProps {
	setLogin: (login: ILogin) => void
}

const LoginForm = ({ setLogin }: LoginFormProps) => {
	const [ loginError, setLoginError ] = useState('')

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const logindata = new FormData(event.currentTarget)
		try {
			fetchAdmin('user/login', logindata, 'POST').then(data => {
				setLogin(data.data)
			})
		} catch (err) {
			setLoginError('Ungültige Zugangsdaten')
		}
	};

	return (
		<Container component="main" maxWidth="xs">
			<Box sx={{
				marginTop: 8,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}>
				<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Backend Login
				</Typography>
				<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
					<TextField margin="normal" required fullWidth name="username" label="E-Mail" type="email" autoComplete="email" autoFocus />
					<TextField margin="normal" required fullWidth name="password" label="Passwort" type="password" autoComplete="current-password" />
					{/* <FormControlLabel control={<Checkbox value="1" name="remember" color="primary" />} label="Auf diesem Computer angemeldet bleiben" /> */}
					{loginError && <Alert severity="error">{loginError}</Alert>}
					<Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>Einloggen</Button>
				</Box>
			</Box>
		</Container>
	)
}

export default LoginForm