import React, { createContext, useEffect, useState } from 'react'
import { Alert, Dialog, DialogContent, DialogTitle, Grid2, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { useSnackbar } from 'notistack'
import CloseIcon from '@mui/icons-material/Close'
import { AdminError, AdminResponseError, AdminResponseTraceError, showAdminError } from 'query/xhttp'
import { ILogin } from 'query/rememberUser'

interface UserStore {
	user?: ILogin
	setUser: (user: ILogin) => void
	isAdmin: () => boolean
	getVeranstalter: () => number[]
	isVeranstalter: () => boolean
	hasVeranstalterSystem: () => boolean
	getStammdaten: () => number[]
	isStammdaten: () => boolean
	isRefadmin: () => boolean;
	isMultiplerefs: () => boolean;
	displayError: (response: AdminResponseError | AdminResponseTraceError | Error) => void
}

export const UserContext = createContext<UserStore>({} as UserStore)

export function UserProvider ({ children }: React.PropsWithChildren) {
	const [ user, setUser ] = useState<ILogin | undefined>(undefined)
	const { enqueueSnackbar } = useSnackbar()
	const [ open, setOpen ] = useState(false)
	const [ error, setError ] = useState<AdminResponseTraceError | undefined>(undefined)

	const handleClose = () => setOpen(false)

	useEffect(() => {
		if (error) {
			setOpen(true)
		}
	}, [error])

	const isAdmin = () => user !== undefined && user.roles.includes('ad')

	const displayError = (response: AdminResponseError | AdminResponseTraceError | Error) => {
		// check if it's an error
		if (response instanceof AdminError) {
			const data = response.getResponse()

			if (isAdmin() && showAdminError(data)) {
				// Render trace in modal for admins
				// Code to render trace in modal goes here
				setError(data)
			} else {
				// Show snackbar error message for non-admins
				enqueueSnackbar(data.message, { variant: 'error' })
			}
			
		} else {
			// Show snackbar error message for non-admins
			enqueueSnackbar(response.message, { variant: 'error' })
		}
	}

	const getVeranstalter = () => {

		// das führt zu NaN

		return user?.roles.filter(role => role.match(/^va:\d+$/)).map(parseInt) || []
	}

	const isVeranstalter = () => {
		return user?.roles.includes('va') || false
	}

	const hasVeranstalterSystem = () => {
		return user?.roles.includes('vasys') || false
	}

	const getStammdaten = () => {
		return user?.roles.filter(role => role.match(/^sd:\d+$/)).map(parseInt) || []
	}

	const isStammdaten = () => {
		return user?.roles.includes('sd') || false
	}

	const isRefadmin = () => {
		return user?.roles.includes('refadmin') || false
	}

	const isMultiplerefs = () => {
		return user?.roles.includes('multiplerefs') || false
	}

	return (
		<UserContext.Provider value={{ user, setUser, isAdmin, getVeranstalter, hasVeranstalterSystem, isVeranstalter, getStammdaten, isStammdaten, isRefadmin, isMultiplerefs, displayError }}>
			{children}
			{isAdmin() && (
				<Dialog open={open} onClose={handleClose}>
					<DialogTitle>
						<Grid2 container spacing="2" justifyContent="space-between" alignItems="baseline">
							<Grid2 size="grow">
								Fehlermeldung
							</Grid2>
							<Grid2 size="auto">
								<IconButton color="error" onClick={handleClose}>
									<CloseIcon />
								</IconButton>
							</Grid2>
						</Grid2>
					</DialogTitle>
					<DialogContent>
						{error && (
							<>
								<Alert severity="warning">
									{error.message}
								</Alert>
								<List dense>
									<ListItem key={-1}>
										<ListItemIcon>
											#0
										</ListItemIcon>
										<ListItemText primary={error.file + ':' + error.line} />
									</ListItem>
									{error.trace.map((trace, index) => (
										<ListItem key={index}>
											<ListItemIcon>
												#{index + 1}
											</ListItemIcon>
											<ListItemText primary={trace.file + ':' + trace.line} secondary={trace.class + trace.type + trace.function} />
										</ListItem>
									))}
								</List>
							</>
						)}
					</DialogContent>
				</Dialog>
			)}
		</UserContext.Provider>
	)
}