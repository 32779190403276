import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Freeze } from 'react-freeze'

type TabContentProps = {
	id: string,
	active: boolean,
	children: React.ReactNode,
}

export const TabContent = (props: TabContentProps) => {

	const { active, children, id } = props
	
	const content = useMemo(() => {
		return children
	}, [ id ])
	
	return (
		<Freeze freeze={!active}>
			<ErrorBoundary fallback={<p>Es ist ein Fehler aufgetreten!</p>}>
				<Box sx={{ height: 'calc(100vh - 48px)', overflow: 'hidden', width: '100%', maxWidth: '100%', ...(!active && { display: 'none' })}}>
					{content}
				</Box>
			</ErrorBoundary>
		</Freeze>
	)
}