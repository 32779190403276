import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { fetchAdmin } from 'query/fetchAdmin'
import { AdminResponse } from 'query/xhttp'

export interface ILogin {
	id: number
	name: string
	letters: string
	alid: string
	navigation: INavigation[]
	roles: string[]
}

export type INavigation = {
	id: number,
	label: string,
	component?: string,
	children?: INavigation[]
}

const rememberUserQuery = () => useQuery({
	queryKey: ['login'],
	queryFn: () => fetchAdmin('user/login'),
	cacheTime: 0
})

type startRefetchProps = {
	refetch: (options?: RefetchOptions & RefetchQueryFilters) => Promise<QueryObserverResult<AxiosResponse<AdminResponse, Error>>>,
}

export const startRefetch = ({ refetch }: startRefetchProps) => {
	return setInterval(() => {
		refetch({ throwOnError: true })
			.catch(() => {
				const queryClient = useQueryClient()
				queryClient.invalidateQueries({ queryKey: ['login'] })
				window.location.reload()
			})
	}, 10 * 60 * 1000) // 10 min
}

export default rememberUserQuery