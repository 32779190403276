import React from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const componentMap: {[key: string]: React.FunctionComponent<any>} = {
	AdminDashboard: React.lazy(() => import('dashboard/AdminDashboard')),
	Agencies: React.lazy(() => import('agencies/Agencies')),
	AgenciesUserCheck: React.lazy(() => import('agencies/AgenciesUserCheck')),
	AgenciesUserList: React.lazy(() => import('agencies/AgenciesUserList')),
	AgencyUserForm: React.lazy(() => import('agencies/AgencyUserForm')),
	AgencyTransferList: React.lazy(() => import('agencies/users/AgencyTransferList')),
	Banners: React.lazy(() => import('banners/Banners')),
	Bistro: React.lazy(() => import('agencies/Bistro')),
	Booking: React.lazy(() => import('booking/Booking')),
	BookingCancel: React.lazy(() => import('booking/BookingCancel')),
	BookingProvider: React.lazy(() => import('booking/BookingProvider')),
	Campaigns: React.lazy(() => import('agencies/Campaigns')),
	CompassVersion: React.lazy(() => import('monitor/users/CompassVersion')),
	ContactRequests: React.lazy(() => import('agencies/ContactRequests')),
	Cruisecache: React.lazy(() => import('monitor/Cruisecache')),
	CruiseContent: React.lazy(() => import('cruise/Contents')),
	CruiseContingents: React.lazy(() => import('contingent/Cruises')),
	CruisedataPrices: React.lazy(() => import('cruise/Prices')),
	Cruiseports: React.lazy(() => import('cruise/Cruiseports')),
	CruiseportsAirports: React.lazy(() => import('cruise/CruiseportsAirports')),
	Deckplan: React.lazy(() => import('cruise/Deckplan')),
	Deckplans: React.lazy(() => import('cruise/Deckplan/Deckplans')),
	Downloader: React.lazy(() => import('monitor/Downloader')),
	Emails: React.lazy(() => import('monitor/Emails')),
	EMailTemplate: React.lazy(() => import('agencies/email/EMailTemplate')),
	EMailTemplates: React.lazy(() => import('agencies/email/EMailTemplates')),
	FlightContingents: React.lazy(() => import('contingent/Flights')),
	Funnel: React.lazy(() => import('dashboard/charts/Funnel')),
	GetCabins: React.lazy(() => import('monitor/GetCabins')),
	GiataHotels: React.lazy(() => import('hotel/GiataHotels')),
	HotelDestinations: React.lazy(() => import('hotel/HotelDestinations')),
	Hotmix: React.lazy(() => import('monitor/Hotmix')),
	Http: React.lazy(() => import('dashboard/charts/Http')),
	I18nItinerary: React.lazy(() => import('cruise/I18nItinerary/I18nItinerary')),
	I18nItineraryView: React.lazy(() => import('cruise/I18nItinerary/I18nItineraryView')),
	// IBEAdmin: React.lazy(() => import('ibe/components/Admin')),
	IBEConfigs: React.lazy(() => import('ibe/admin/Configs')),
	IBEError: React.lazy(() => import('ibe/IBEError')),
	IBEErrors: React.lazy(() => import('ibe/IBEErrors')),
	IBEUsers: React.lazy(() => import('ibe/IBEUsers')),
	Imports: React.lazy(() => import('offer/Imports')),
	IncomingAgencies: React.lazy(() => import('hotel/IncomingAgencies')),
	InterfaceRequest: React.lazy(() => import('monitor/InterfaceRequest')),
	Interfaces: React.lazy(() => import('monitor/Interfaces')),
	Itineraries: React.lazy(() => import('cruise/Itineraries')),
	ItineraryNames: React.lazy(() => import('translation/ItineraryNames')),
	Jobruns: React.lazy(() => import('monitor/jobs/Jobruns')),
	Jobs: React.lazy(() => import('monitor/jobs/Jobs')),
	JobStepsArchive: React.lazy(() => import('monitor/jobs/JobStepsArchive')),
	MeinlaPorts: React.lazy(() => import('adds/MeinlaPorts')),
	MeinlaShips: React.lazy(() => import('adds/MeinlaShips')),
	MscAgency: React.lazy(() => import('agencies/msc/MscAgency')),
	MscMidoffice: React.lazy(() => import('agencies/msc/MscMidoffice')),
	Offers: React.lazy(() => import('offer/Offers')),
	Paxconnect: React.lazy(() => import('monitor/Paxconnect')),
	PhpErrors: React.lazy(() => import('monitor/PhpErrors')),
	PMCProviders: React.lazy(() => import('cruise/PMCProviders')),
	Portmappings: React.lazy(() => import('cruise/Portmappings')),
	PriceDifference: React.lazy(() => import('booking/PriceDifference')),
	ProgrammContingents: React.lazy(() => import('contingent/Programs')),
	Promos: React.lazy(() => import('offer/Promos')),
	Promotion: React.lazy(() => import('offer/Promos/Promo')),
	Redis: React.lazy(() => import('monitor/Redis')),
	ReferenzProvider: React.lazy(() => import('agencies/provider/ReferenzProvider')),
	RoomCrscodes: React.lazy(() => import('hotel/RoomCrscodes')),
	Roomtypes: React.lazy(() => import('hotel/Roomtypes')),
	SeasonDestinations: React.lazy(() => import('hotel/SeasonDestinations')),
	SelcoUsers: React.lazy(() => import('agencies/selco/SelcoUsers')),
	SelcoLog: React.lazy(() => import('agencies/selco/SelcoLog')),
	Services: React.lazy(() => import('cruise/Services')),
	Ship: React.lazy(() => import('cruise/Ship')),
	ShipCabins: React.lazy(() => import('cruise/ShipCabins')),
	ShipEquipments: React.lazy(() => import('cruise/ShipEquipments')),
	Ships: React.lazy(() => import('cruise/Ships')),
	ShipView: React.lazy(() => import('cruise/ShipView')),
	SQLError: React.lazy(() => import('monitor/sql/SQLError')),
	SQLTraces: React.lazy(() => import('monitor/sql/SQLTraces')),
	Timetables: React.lazy(() => import('cruise/Timetables')),
	TimetableTransferlist: React.lazy(() => import('cruise/TimetableTransferlist')),
	Touroperators: React.lazy(() => import('offer/Touroperators')),
	TransferAgencies: React.lazy(() => import('hotel/TransferAgencies')),
	Transfers: React.lazy(() => import('hotel/Transfers')),
	TranslationForm: React.lazy(() => import('ibe/TranslationForm')),
	Translations: React.lazy(() => import('ibe/Translations')),
	UserLoginError: React.lazy(() => import('monitor/users/UserLoginError')),
	UserLogins: React.lazy(() => import('monitor/users/UserLogins')),
	Users: React.lazy(() => import('agencies/users/Users')),
	// Vorgang: React.lazy(() => import('agencies/vorgang/Vorgang')),
	VorgangList: React.lazy(() => import('agencies/vorgang/VorgangList')),
	Xdata: React.lazy(() => import('monitor/Xdata')),
	Zugänge: React.lazy(() => import('agencies/zugang/Zugänge')),
	ZusatzContingents: React.lazy(() => import('contingent/Zusatz')),
}

export default (component: string) => {
	if (componentMap[component] === undefined) {
		throw new Error('Component not found: ' + component)
	}

	return componentMap[component]
}
