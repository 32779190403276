
import React from 'react'
import { LicenseInfo } from '@mui/x-license';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Darkmode } from 'general/context/Darkmode'
import Login from 'general/Login'

LicenseInfo.setLicenseKey('71da166b97a0e456049a53cf739e4cdeTz04NjIxMyxFPTE3NDE3OTQ2NDIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=')

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 0,
			refetchOnWindowFocus: false,
			refetchInterval: false,
			retry: false
		}
	}
})

const App = () => {
	return (
		<QueryClientProvider client={queryClient}>
			<Darkmode>
				<Login />
			</Darkmode>
		</QueryClientProvider>
	);
}

export default App;
