import { useQuery } from '@tanstack/react-query'
import { AdminDetailResponse, fetchAdmin } from 'query/fetchAdmin'

export type ThreeItems = {
	name: string
	path: string
	children?: ThreeItems[]
}

export const threeItemsQuery = (load: boolean) => {
	return useQuery<AdminDetailResponse<ThreeItems>>({
		queryKey: ['filepicker', 'three'],
		queryFn: () => fetchAdmin('filepicker/threeItems'),
		cacheTime: 0,
		enabled: load,
	})
}

export const loadFilesQuery = (path: string) => {
	const form = new FormData()
	form.append('path', path)

	return useQuery<AdminDetailResponse<string[]>>({
		queryKey: ['filepicker', path],
		queryFn: () => fetchAdmin('filepicker/loadFiles', form),
		cacheTime: 0,
		enabled: path !== '/files'
	})
}