import React from 'react'
import loadComponent from 'query/componentLoader';
import { Tab } from 'general/context/TabContext';
import { IFrame } from 'general/IFrame';

// init components from url
export const getComponent = (component: string, params: string[] = []): Tab => {
	if (component === 'route') {
		/**
		 * @example #route:123456
		 */
		const Itinerary = loadComponent('I18nItinerary')
		const id = parseInt(params[0])

		return {
			id: 'itinerary_' + id,
			label: 'Route ' + id,
			loadedComponent: () => <Itinerary id={id} />,
		}
	}

	if (component === 'xhttp') {
		/**
		 * @example #xhttp:xlog_xhttp_2024-03:hk2aYY4BR2rfI3oJMUMM
		 */
		const id = [params[0], params[1]].join(':')
		const InterfaceRequest = loadComponent('InterfaceRequest')
		return {
			id: 'interfaces_' + id,
			label: 'Request',
			loadedComponent: () => <InterfaceRequest id={id} />
		}
	}

	if (component === 'err') {
		/**
		 * @example #err:123456
		 */
		const IBEError = loadComponent('IBEError')
		const id = parseInt(params[0])
		return {
			id: 'ibe_error_' + id,
			label: 'Error ' + id,
			loadedComponent: () => <IBEError id={id} />
		}
	}

	if (component === 'email') {
		/**
		 * @example #email:123456
		 */
		const Emails = loadComponent('Emails')
		const id = parseInt(params[0])
		return {
			id: 'emails',
			label: 'E-Mails',
			loadedComponent: () => <Emails id={id} />
		}
	}

	if (component === 'job') {
		/**
		 * @example #job:123456
		 */
		const Jobruns = loadComponent('Jobruns')
		const id = parseInt(params[0])
		return {
			id: 'jobs',
			label: 'Jobs',
			loadedComponent: () => <Jobruns id={id} />
		}
	}

	if (component === 'vorgangsverwaltung') {
		const Component = loadComponent('VorgangList')
		return {
			id: 'vorgangsverwaltung',
			label: 'Vorgangsverwaltung',
			loadedComponent: () => <Component />
		}
	}

	if (component === 'vorgang') {
		/**
		 * @example #vorgang:123456 | d63caa4bcf6c537e9d9ebfb4ff56e20bdbe3eb73
		 * @todo security
		 */
		// const Component = loadComponent('Vorgang')
		const id = String(params[0]) // id, bid
		return {
			id: 'vorgang' + id,
			label: 'Vorgang ' + id.slice(0,10),
			loadedComponent: () => <IFrame src={'frames/vorgang/vorgang.php?noreturn=1&id=' + id} />,
		}
	}

	throw new Error('Component not found: ' + component)
}